<template>
    <div class="my">
        <div class="h5-box">
            <div class="my-head">
                <h1 class="title">{{ $t('tabbar.my') }}</h1>
                <div class="my-head__container">
                    <div class="my-head__left">
                        <img class="my-avatar" src="../../assets/logo-new.png" alt="">
                        <div>
                            <!--            <p style="margin-bottom: 6px;font-weight: 700;">ID：{{ user.id }}</p>-->
                            <p class="line name">{{ $t('user.account') }}：{{ user.username }}</p>
                            <p class="tag">ID: {{ user.id }}</p>
                            <p class="line">{{ $t('user.invitation_code') }}：{{ user.invitation_code }}</p>
                            <p class="tag copy" @click="copyCode(user.invitation_code)"> {{$t('user.copy_code')}}</p>
                        </div>
                    </div>
                    <a class="my-tiktok" href="https://www.tiktok.com/" target="_blank">
                        <img class="my-logo" src="../../assets/logo-text-white.png" alt="">
                        <img src="../../assets/icon-right-white.png" class="icon-right-double" alt="">
                    </a>
                </div>
                <!-- <img class="my-head__bg" src="../../assets/user_bg.png" alt=""> -->
            </div>

            <div class="my-balance">
                <div>
                    {{ $t('user.balance') }}：<br><span class="my-balance__text">{{( user.balance || '0.00') + $t('币单位')}}</span>
                </div>
                <div class="tag" @click="jump">
                    <van-icon class="icon" name="like" color="#FA64B6" />
                    <span>{{ $t('user.endowment') }}</span>
                </div>
            </div>

            <div class="my-recharge">
                <van-button color="transparent" round block to="/withdraw_funds">
                    <img class="icon" src="../../assets/icon-my-tx.png" alt="">
                    {{ $t('user.withdraw_cash_now') }}
                </van-button>
                <hr class="hr" />
                <van-button class="btn-right" color="transparent" round block to="/recharge">
                    <img class="icon" src="../../assets/icon-my-cz.png" alt="">
                    {{ $t('user.go_recharge') }}
                </van-button>
            </div>

            <van-cell :title="$t('user.tiktok_shop')" url="https://seller.tiktokglobalshop.com/account/login" class="cell-style" :border="false" is-link>
                <img src="../../assets/icon-my-1.png" slot="icon" class="left-icon" alt="">
            </van-cell>
            <van-cell :title="$t('user.security_center')" class="cell-style" :border="false" is-link to="/settings">
                <img src="../../assets/icon-my-2.png" slot="icon" class="left-icon" alt="">
            </van-cell>
            <van-cell :title="$t('user.bank_record')" class="cell-style" :border="false" is-link to="/bank_record">
                <img src="../../assets/icon-my-3.png" slot="icon" class="left-icon" alt="">
            </van-cell>
            <van-cell :title="$t('user.transaction_records')" class="cell-style" :border="false" is-link to="/transaction_record">
                <img src="../../assets/icon-my-4.png" slot="icon" class="left-icon" alt="">
            </van-cell>
            <van-cell :title="$t('user.bank_card')" class="cell-style" :border="false" is-link to="/bank_card">
                <img src="../../assets/icon-my-5.png" slot="icon" class="left-icon" alt="">
            </van-cell>
            <!-- <van-cell :title="$t('user.language')" class="cell-style" :border="false" is-link @click="show = true">
                <img src="../../assets/icon-my-1.png" slot="icon" class="left-icon" alt="">
            </van-cell> -->
            <div style="padding: 24px;">
                <van-button type="danger" class="btn-bg" plain block color="#FF009E" round :loading="loading" @click="_logout">
                    {{ $t('user.logout') }}
                </van-button>
            </div>

            <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
        </div>
        <div class="pc-box">
            <div class="left">
                <van-cell :title="$t('user.tiktok_shop')" url="https://seller.tiktokglobalshop.com/account/login" :class="selectCell==1?'cell-style cell-selected':'cell-style'" :border="false" @click="selectCell=1">
                    <img src="../../assets/icon-my-1.png" slot="icon" class="left-icon" alt="">
                </van-cell>
                <van-cell :title="$t('user.security_center')" :class="selectCell==2?'cell-style cell-selected':'cell-style'" :border="false" to="/my/settings" @click="selectCell=2">
                    <img src="../../assets/icon-my-2.png" slot="icon" class="left-icon" alt="">
                </van-cell>
                <van-cell :title="$t('user.bank_record')" :class="selectCell==3?'cell-style cell-selected':'cell-style'" :border="false" to="/my/bank_record" @click="selectCell=3">
                    <img src="../../assets/icon-my-3.png" slot="icon" class="left-icon" alt="">
                </van-cell>
                <van-cell :title="$t('user.transaction_records')" :class="selectCell==4?'cell-style cell-selected':'cell-style'" :border="false" to="/my/transaction_record" @click="selectCell=4">
                    <img src="../../assets/icon-my-4.png" slot="icon" class="left-icon" alt="">
                </van-cell>
                <van-cell :title="$t('user.bank_card')" :class="selectCell==5?'cell-style cell-selected':'cell-style'" :border="false" to="/my/bank_card" @click="selectCell=5">
                    <img src="../../assets/icon-my-5.png" slot="icon" class="left-icon" alt="">
                </van-cell>
                <!-- <van-cell :title="$t('user.language')" class="cell-style" :border="false" @click="show = true">
                    <img src="../../assets/icon-my-1.png" slot="icon" class="left-icon" alt="">
                </van-cell> -->
            </div>
            <div class="right">
                <router-view></router-view>
            </div>
        </div>
        <Tabbar :key="lang" bgColor="#FFFFFF" :selectTab="3" />
    </div>
</template>

<script>
import Tabbar from "@/components/Tabbar/Tabbar.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { greeting, logout } from "@/api";

export default {
    data() {
        return {
            loading: false,
            show: false,
            actions: [
                // {name: 'English', value: 'en_US'},
                { name: 'Tiếng Việt', value: 'vi_VN' },
                { name: 'ไทย', value: 'th_TH', },
                { name: '简体中文', value: 'zh_CN' }],
            selectCell: undefined,
        };
    },
    components: {
        Tabbar,
    },
    computed: {
        ...mapState(["user", 'lang']),
    },
    methods: {
        ...mapActions(['fetchUser']),
        ...mapMutations(['setUser', 'setLang']),
        onSelect(item) {
            // 可以通过 close-on-click-action 属性开启自动收起
            this.show = false;
            this.$i18n.locale = item.value;
            this.setLang(item.value);
            greeting(item.value).then(() => {
                // this.$toast(res.message);
            });
        },
        _logout() {
            this.loading = true;
            logout().then(() => {
                this.setUser({});
                this.loading = false;
                this.$router.push("/login");
            });
        },
        jump() {
            this.$router.push("/endowment");
        },
        jumpPc() {
            this.$router.push("/my/endowment");
        },
        copyCode(code) {
            const link = window.location.protocol + window.location.host + '/#/register?code=' + code;
            // console.log(link)
            navigator.clipboard.writeText(link).then(() => {
                this.$toast(this.$t('user.copy_success'));
            }).catch(() => {
                this.$toast(this.$t('user.copy_err'));
            });

        }
    },
    created() {
        this.fetchUser();
    },
}
</script>

<style lang="less" scoped>
.my {
    min-height: 100vh;
    background: url(../../assets/my-bg.png) left top no-repeat var(--bg-color);
    background-size: 100%;
}
.my-recharge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    background: url(../../assets/my-btn-bg.png) left top no-repeat;
    background-size: 100%;
    margin-top: -61px;
    position: relative;
    z-index: 10;
    padding: 0 16px 10px 16px;
    .hr {
        width: 0;
        height: 14px;
        border-left: 1px solid #d8def3;
    }
    .icon {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 6px;
    }
    .btn-right {
        padding-right: 11px;
    }
}

.my-balance {
    margin: 22px 27px 0 16px;
    width: 332px;
    height: 132px;
    background: url("../../assets/money-bg.png") left top no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-size: 14px;
    color: #999;
    position: relative;
    .my-balance__text {
        font-weight: 600;
        font-size: 18px;
        color: #333;
    }
    .tag {
        position: absolute;
        right: -15px;
        top: 21px;
        min-width: 82px;
        height: 36px;
        padding: 0 5px;
        padding-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #fc1157;
        background: url("../../assets/jx-bg.png") left top no-repeat;
        background-size: 100% 100%;
        text-align: center;
        white-space: nowrap;
        .icon {
            margin-top: 4px;
        }
    }
}

.my-head {
    .title {
        line-height: 35px;
        text-align: center;
        font-size: 18px;
        color: var(--font-color);
    }
    .my-head__container {
        padding-left: 16px;
        display: flex;
        justify-content: space-between;

        .my-head__left {
            display: flex;
            align-items: center;
            .line {
                font-size: 13px;
                color: #949494;
            }
            .line.name {
                font-weight: 600;
                font-size: 16px;
                color: var(--font-color);
            }
            .tag {
                width: fit-content;
                padding: 0 10px;
                margin: 4px 0;
                display: flex;
                justify-content: center;
                align-content: center;
                background: #a1f0f1;
                border-radius: 13px 13px 13px 13px;
                color: #3663a7;
            }
            .tag.copy {
                cursor: pointer;
            }
            .my-avatar {
                width: 62px;
                height: 62px;
                border-radius: 50%;
                margin-right: 10px;
                background: #d8def3;
            }
        }

        .my-tiktok {
            width: 95px;
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #000;
            padding: 0 6px 0 10px;
            border-radius: 20px 0px 0px 20px;

            .my-logo {
                width: 61.15px;
                height: 18.07px;
                margin-right: 6px;
            }
        }
    }
}
.van-cell__right-icon {
    color: var(--font-color);
}
.left-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}
.my-head__bg {
    width: 100%;
}
.cell-style {
    padding-left: 29px;
    padding-right: 26px;
    background: var(--bg-color);
    color: var(--font-color);
}
.btn-bg {
    background: var(--bg-color);
}
.pc-box {
    display: none;
}
@media only screen and (min-width: 750px) {
    .h5-box {
        display: none;
    }
    .my {
        background: url(../../assets/my-bg-pc.png) left top no-repeat
            var(--bg-color);
        background-size: 100%;
    }
    .pc-box {
        width: 100%;
        min-height: 100vh;
        display: flex;
        padding: 113px 16% 0 16%;
        justify-content: space-between;
    }
    .pc-box .left,
    .pc-box .right {
        background: #242229;
        border-radius: 18px 18px 18px 18px;
    }
    .pc-box .left {
        width: 280px;
        margin-right: 30px;
        flex-shrink: 0;
        padding-top: 36px;
        .cell-style.cell-selected {
            background: var(--bg-color);
        }
        .cell-style {
            padding: 0 34px;
            height: 64px;
            line-height: 64px;
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            background: #242229;
            .left-icon {
                width: 28px;
                height: 28px;
                margin-right: 10px;
            }
        }
    }
    .pc-box .right {
        width: 100%;
        flex-shrink: 1;
        .my-head {
            .my-head__container {
                padding-left: 0;
                display: block;
                .my-avatar {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-right: 0;
                    display: block;
                    margin: 27px auto;
                }
                .name {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--font-color);
                }
                .df-center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    .tag {
                        height: 28px;
                        line-height: 28px;
                        padding: 0 10px;
                        border-radius: 14px;
                        border: 0;
                        color: #3663a7;
                        margin: 10px 5px 16px 5px;
                        background: #a1f0f1;
                    }
                    .tag.line {
                        color: var(--font-color);
                        background: rgba(198, 198, 198, 0.34);
                    }
                }
                .my-tiktok {
                    width: 235px;
                    height: 48px;
                    display: flex;
                    margin: 0 auto;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(
                        270deg,
                        #a44afb 0%,
                        #f85dc6 100%
                    );
                    border-radius: 25px 25px 25px 25px;
                    position: relative;
                    .my-logo {
                        width: 78px;
                        height: 23px;
                        margin-right: 0;
                    }
                    .icon-right-double {
                        width: 8px;
                        height: 9px;
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        margin-top: -5px;
                    }
                }
            }
        }

        .my-balance {
            margin: 23px auto;
            width: 90.4%;
            height: 148px;
            border-radius: 30px;
            background: url("../../assets/money-bg-pc.png") center center
                no-repeat;
            background-size: cover;
            padding: 0 5.3%;
            font-size: 14px;
            display: flex;
            align-items: center;
            .text-style {
                font-size: 18px;
                color: #999;
            }
            .my-balance__text {
                font-size: 30px;
                margin-bottom: 8px;
            }
        }
        .my-recharge {
            height: initial;
            justify-content: initial;
            background: none;
            margin-top: 0;
            position: relative;
            z-index: 10;
            padding: 0;
            .icon {
                width: 26px;
                height: 26px;
                vertical-align: middle;
                margin-right: 8px;
            }
            .btn-right {
                padding-right: 0;
            }
            .btn {
                width: 128px;
                height: 42px;
                border-radius: 10px 10px 10px 10px;
                border: 1px solid #333333;
                font-size: 16px;
                color: #333333;
                background: transparent;
                padding: 0;
                margin-right: 25px;
            }
            .tag-pc {
                min-width: 82px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                color: #fc1157;
                text-align: center;
                white-space: nowrap;
                .tag-icon {
                    margin-right: 5px;
                }
            }
        }
        .btn-logout {
            width: 319px;
            height: 48px;
            border-radius: 25px 25px 25px 25px;
            border: 1px solid #ff009e;
            font-size: 16px;
            color: #ff009e;
            margin: 45px auto;
            background: transparent;
        }
    }
}
</style>
